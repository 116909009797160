<template>
<g>
  <svg:style>
     .cls-2, .cls-3, .cls-4, .cls-5 {
      stroke: #aeaeae;
      stroke-miterlimit: 10;
      stroke-width: 0.25px;
    }

    .cls-2 {
      fill: url(#handle-gradient-5);
    }

    .cls-3 {
      fill: url(#handle-gradient-2);
    }

    .cls-4 {
      fill: url(#handle-gradient-3);
    }

    .cls-5 {
      fill: url(#handle-gradient-4);
    }

  </svg:style>
  <linearGradient id="handle-gradient"
    x1="11.29"
    y1="150.41"
    x2="16.95"
    y2="150.41"
    gradientUnits="userSpaceOnUse">
    <stop offset="0.01" stop-color="#babbbc"/>
    <stop offset="0.29" stop-color="#989a9e"/>
    <stop offset="0.65" stop-color="#bdbdc1"/>
    <stop offset="0.87" stop-color="#9d9fa2"/>
    <stop offset="1" stop-color="#a6a8aa"/>
    <stop offset="1" stop-color="#c2c5c9"/>
  </linearGradient>

  <linearGradient id="handle-gradient-2" data-name="handle-gradient"
    :x1="inox1X"
    :y1="inox1Y"
    :x2="inox1X + inoxWidth"
    :y2="inox1Y + 2.13"
    xlink:href="#handle-gradient"/>

  <linearGradient id="handle-gradient-3" data-name="handle-gradient"
    :x1="inox1X"
    :y1="inox2Y"
    :x2="inox1X + inoxWidth"
    :y2="inox2Y + 2.13"
    xlink:href="#handle-gradient"/>

  <linearGradient id="handle-gradient-4" data-name="handle-gradient"
    :x1="inox1X"
    :y1="inox3Y"
    :x2="inox1X + inoxWidth"
    :y2="inox3Y + 2.13"
    xlink:href="#handle-gradient"/>

  <linearGradient id="handle-gradient-5" data-name="handle-gradient"
    :x1="inox1X"
    :y1="inox4Y"
    :x2="inox1X + inoxWidth"
    :y2="inox4Y + 2.13"
    xlink:href="#handle-gradient"/>

  <filter id="inset-shadow">
    <feOffset dx="0" dy="0"/>
    <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
    <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
    <feFlood flood-color="black" flood-opacity="1" result="color"/>
    <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
    <feComponentTransfer in="shadow" result="shadow">
      <feFuncA type="linear" slope="1.25"/>
    </feComponentTransfer>
    <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
  </filter>
  <g id="L02">
    <rect id="inox"
          v-if="showInox"
          data-name="inox"
          class="cls-2"
          :x="inox1X"
          :y="inox1Y"
          :width="inoxWidth"
          height="2.12"/>
    <rect id="inox-2"
          v-if="showInox"
          data-name="inox"
          class="cls-3"
          :x="inox1X"
          :y="inox2Y"
          :width="inoxWidth"
          height="2.13"/>
    <rect id="inox-3"
          v-if="showInox"
          data-name="inox"
          class="cls-4"
          :x="inox1X"
          :y="inox3Y"
          :width="inoxWidth"
          height="2.12"/>
    <rect id="inox-4"
          v-if="showInox"
          data-name="inox"
          class="cls-5"
          :x="inox1X"
          :y="inox4Y"
          :width="inoxWidth"
          height="2.13"/>
  </g>
</g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inox2Left: 22,
      leaf2Left: 7,
    }
  },
  computed: {
    inox1X() {
      return this.showBg ?
        this.doorLeftWidth + this.inox2Left :
        this.doorLeftWidth + this.leaf2Left
    },
    inox1Y() {
      return this.doorTopHeight + this.doorHeight * .2 + 2
    },
    inox2Y() {
      return this.doorTopHeight + this.doorHeight * .4 + 2
    },
    inox3Y() {
      return this.doorTopHeight + this.doorHeight * .6 + 2
    },
    inox4Y() {
      return this.doorTopHeight + this.doorHeight * .8 + 2
    },
    inoxWidth() {
      return this.showBg ?
        this.doorWidth - this.inox2Left * 2 :
        this.doorWidth - this.leaf2Left * 2
    },
  }
}
</script>
